import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import unified from 'unified'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import rehype2react from 'rehype-react'
import sectionize from 'remark-sectionize'
import { useSanityRoleBasedContent } from '../../lib/sanity/hooks/useSanityRoleBasedContent'

const markdownProcessor = unified()
  .use(markdown)
  .use(sectionize)
  .use(remark2rehype)
  .use(rehype2react, { createElement: React.createElement })

const BasicArticle = ({
  className,
  title,
  lastModified,
  content,
  keepSectionsTogether,
  roleBasedContent,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!shouldDisplay) {
    return null
  }

  return (
    <article className={`${className} basic-article`}>
      <header className="header-container">
        <h1 id="mainContent">{title}</h1>
        {lastModified && (
          <p>
            <span>Last Modified:</span>&nbsp;
            <span>{lastModified}</span>
          </p>
        )}
      </header>
      <div
        className={`content-container ${keepSectionsTogether ? 'keep-sections-together' : ''} ${
          title === 'Proposition 65 Warning' ? 'prop-65-warning' : ''
        } ${
          title === 'Privacy Notice for California Residents'
            ? 'privacy-notice-for-california-residents'
            : ''
        } ${title === 'Privacy Notice' ? 'privacy-notice' : ''}`}
      >
        {markdownProcessor.processSync(content).result}
      </div>
    </article>
  )
}

BasicArticle.defaultProps = {
  className: '',
}

BasicArticle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  lastModified: PropTypes.string,
  content: PropTypes.string,
  keepSectionsTogether: PropTypes.bool,
  roleBasedContent: PropTypes.shape({
    enabled: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
}

const StyledBasicArticle = styled(BasicArticle)(({ theme }) => ({
  '&.basic-article': {
    backgroundColor: theme.colors.grayscale.white,
    color: theme.colors.brand.gray,
    position: 'relative',
    textAlign: 'left',
    lineHeight: '1.5',
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '.125rem',
      backgroundColor: theme.colors.grayscale.white,
    },
    'h1, h2, h3, h4, h5, h6': {
      color: '#051120',
      marginTop: '0',
      marginBottom: '.5rem',
      fontWeight: '500',
      lineHeight: '1.2',
    },
    'dl, ol, ul': {
      marginTop: '0',
      marginBottom: '1rem',
    },
    em: {
      textDecoration: 'underline',
    },
    pre: {
      marginLeft: '40px',
      fontFamily: theme.fontFamilies.gotham,
      color: theme.colors.brand.gray,
      fontSize: '0.75rem',
      letterSpacing: '.055em',
      lineHeight: '2',
      marginBottom: '1rem',
    },
    ul: {
      marginBottom: '1rem',
      display: 'block',
      listStyleType: 'disc',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      marginInlineStart: '0',
      paddingInlineStart: '40px',
    },
    a: {
      color: theme.colors.brand.orange,
      textDecoration: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        color: '#d9d9d9',
      },
      '&:focus': {
        outline: '1px solid #fff !important',
        outlineOffset: '5px !important',
      },
    },
    p: {
      marginTop: '0',
      marginBottom: '1rem',
    },
    '.header-container': {
      paddingTop: '2rem',
      paddingBottom: '1rem',
    },
    '.content-container': {
      paddingBottom: '2rem',
      '&.privacy-notice-for-california-residents': {
        'table, th, td': {
          border: '1px solid grey',
        },
        table: {
          width: '100%',
          borderSpacing: '2px',
          marginBottom: '40px',
          'th, td': {
            padding: '0.5em',
            verticalAlign: 'top',
            textAlign: 'left',
          },
          th: {
            fontWeight: 'bold',
          },
          td: {
            paddingBottom: '1.5rem',
            '&:nth-child(3)': {
              textAlign: 'center',
            },
          },
          'thead, tbody': {
            verticalAlign: 'middle',
            borderColor: 'inherit',
          },
        },
        ul: {
          marginBottom: '16px',
        },
        ol: {
          listStyleType: 'decimal',
        },
        pre: {
          marginLeft: '0',
          paddingLeft: '18px',
        },
        section: {
          '&:last-of-type': {
            p: {
              '&:last-of-type': {
                marginLeft: '40px',
              },
            },
          },
        },
      },
      '&.prop-65-warning': {
        p: {
          columnSpan: 'all',
        },
        table: {
          width: '100%',
          display: 'none !important',
          '&:first-of-type': {
            display: 'table !important',
          },
          th: {
            textAlign: 'left',
            '&:nth-child(1)': {
              width: '70%',
            },
            '&:nth-child(2)': {
              width: '30%',
            },
          },
        },
        div: {
          p: {
            '&:first-of-type': {
              marginTop: '-1rem',
              paddingBottom: '1rem',
            },
          },
        },
      },
      '&.privacy-notice': {
        em: {
          textDecoration: 'none',
          fontStyle: 'italic',
        },
      },
    },
    '.header-container, .content-container': {
      paddingLeft: '2.25rem',
      paddingRight: '2.25rem',
      'h1 ~ &': {
        paddingTop: '0',
      },
      h1: {
        fontFamily: theme.fontFamilies.gotham,
        fontWeight: 'bold',
        fontSize: '1.625rem',
      },
      'h2, h3, h4': {
        fontSize: '0.9375rem',
      },
      'p, ul, ol, dl, table': {
        fontFamily: theme.fontFamilies.gotham,
        fontWeight: '400',
        fontSize: '.6875rem',
        letterSpacing: '0.055em',
        lineHeight: '2',
      },
      'ul, ol, dl': {
        paddingLeft: '1.5em',
      },
      'p ~ ul, p ~ ol, p ~ dl': {
        marginTop: '-0.5rem',
      },
      'p ~ h2': {
        marginTop: '2rem',
      },
      table: {
        'th ~ th, td ~ td': {
          paddingLeft: '1rem',
        },
        '&.prop65-products': {
          width: '100%',
          'th, td': {
            '&:nth-child(1)': {
              width: '70%',
            },
            '&:nth-child(2)': {
              width: '30%',
            },
          },
        },
      },
      '.btn': {
        display: 'inline-block',
        fontSize: '0.75rem',
      },
      '.pull-right': {
        float: 'right',
      },
    },
  },
  '@media(min-width: 768px)': {
    '&.basic-article': {
      '&:before': {
        paddingTop: '0.25rem',
      },
      '.header-container': {
        padding: '3rem 4.5rem 1rem',
      },
      '.content-container': {
        padding: '0 4.5rem 3rem',
      },
      '.header-container, .content-container': {
        'p, ul, ol, dl, table': {
          fontSize: '0.75rem',
        },
      },
    },
  },
  '@media(min-width: 992px)': {
    '&.basic-article': {
      '&:before': {
        paddingTop: '0.5rem',
      },
      '.header-container': {
        padding: '4rem 5.5rem 1rem',
      },
      '.content-container': {
        padding: '0 5.5rem 4rem',
        columnCount: '2',
        columnGap: '7.5%',
        '.keep-together, &.keep-sections-together section, table': {
          /* https://stackoverflow.com/questions/7785374/how-to-prevent-column-break-within-an-element */
          '-webkit-column-break-inside': 'avoid' /* Chrome, Safari */,
          pageBreakInside: 'avoid' /* Theoretically FF 20+ */,
          breakInside: 'avoid-column' /* IE 11 */,
        },
        '.span-all': {
          columnSpan: 'all',
        },
        '> p:last-child': {
          marginBottom: '0',
        },
        '~ section, ~ h2, ~ h3': {
          marginTop: '1rem',
        },
        '&.prop-65-warning': {
          table: {
            display: 'table !important',
            '&:first-of-type': {
              display: 'none !important',
            },
          },
        },
      },
    },
  },
}))

export default StyledBasicArticle
