import React from 'react'

import { TermsModalTriggerLink } from './TermsModalTriggerLink'
import { TermsLinkComponent } from '../types'

export const PrivacyNoticeLink: TermsLinkComponent = ({
  className,
  setCurrentlyOpenTermsModal,
  text,
}) => (
  <TermsModalTriggerLink
    className={className}
    modalType="privacyNotice"
    setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
  >
    {text}
  </TermsModalTriggerLink>
)

PrivacyNoticeLink.defaultProps = {
  className: '',
  text: 'Privacy Notice',
}
