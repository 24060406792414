import {
  gothamFamilies,
  robotoCondensedFamilies,
  SecondaryButton,
  TextInput,
} from '@designsforhealth/dfh-react-components'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import useMediaQuery from '../../../hooks/useMediaQuery'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { usePageUrl } from '../../../lib/pages'
import { getLibraryUrlGenerator, libraryPropTypes } from '../../../utils/libraryTools'
import Link from '../../global/Link'
import { LibraryDimmer } from './LibraryDimmer'
import ResourceFiltersLink from './ResourceFiltersLink'

import closeIcon from '../../../img/x-close-gray.svg'

const ClearFiltersButton = ({ showClearFilters }) => {
  return (
    showClearFilters && (
      <div className="clear-filters">
        <Link to="/research-education/library" className="filters-btn">
          Clear Filters
        </Link>
      </div>
    )
  )
}

ClearFiltersButton.propTypes = {
  showClearFilters: PropTypes.bool.isRequired,
}

const ResourceFilters = ({ className, searchOptions, libraryType, meta }) => {
  const { handleSubmit, register } = useForm()
  const { navigate } = usePageUrl()

  const getLibraryUrl = getLibraryUrlGenerator(searchOptions)
  const { count } = meta

  const onKeywordSearch = async ({ query }) => {
    const url = getLibraryUrl({ query })
    try {
      await navigate(url)
    } catch (error) {
      console.error('onKeywordSearch error', error)
    }
  }

  // check if any filters or queries have been applied
  const showClearFilters =
    (searchOptions.filters &&
      (searchOptions.filters.type.length > 0 ||
        searchOptions.filters.name.length > 0 ||
        !!searchOptions.filters.query)) ||
    false

  const filterLinkActive = searchOptions.filters && searchOptions.filters.type.length > 0

  const [isActive, setIsActive] = useState(false)
  const isWide = useMediaQuery({ minWidth: '992px' })

  const toggleClass = useCallback(() => setIsActive((prev) => !prev), [])
  const closeFilters = useCallback(() => setIsActive(false), [])

  const { elementRef: containerRef } = useOutsideClick(closeFilters)

  return (
    <>
      <div
        className={`${className} faceted-filters ${isActive ? 'drawer-open' : ''}`}
        ref={containerRef}
      >
        <button className="close-icon" onClick={closeFilters}>
          <img src={closeIcon} alt="Close icon" />
        </button>
        <ClearFiltersButton showClearFilters={showClearFilters} />
        <form onSubmit={handleSubmit(onKeywordSearch)} className="filter-form">
          <div className="form-group-filter">
            <TextInput
              className="text-input"
              label=""
              name="query"
              placeholder="Filter results"
              register={register}
              defaultValue={searchOptions.filters.query}
            />
            <button type="submit" className="submit-query">
              <FontAwesomeIcon icon={faFilter} />
            </button>
          </div>
        </form>
        <ul className="facets">
          <li className="facet">
            <div className="facet-title">Type</div>
            <ul className="facet-children">
              {libraryType === 'professional' ? (
                <>
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.casiSession}
                    type="casiSession"
                    name="CASI Session"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.catalogTraining}
                    type="catalogTraining"
                    name="Catalog Training"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.categoryBrochure}
                    type="categoryBrochure"
                    name="Category Brochure"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.journalArticle}
                    type="journalArticle"
                    name="Journal Article"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.literatureReference}
                    type="literatureReference"
                    name="Literature Reference"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.patientBrochure}
                    type="patientBrochure"
                    name="Patient Brochure"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.patientEducation}
                    type="patientEducation"
                    name="Patient Education"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.priceList}
                    type="priceList"
                    name="Price List"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.privateLabelForm}
                    type="privateLabelForm"
                    name="Private Label Form"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.productReferenceChart}
                    type="productReferenceChart"
                    name="Product Reference Chart"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.protocol}
                    type="protocol"
                    name="Protocol"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.socialMediaResource}
                    type="socialMediaResource"
                    name="Social Media Resource"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.techSheet}
                    type="techSheet"
                    name="Tech Sheet"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.techSheetFrench}
                    type="techSheetFrench"
                    name="Tech Sheet - French"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.webinar}
                    type="webinar"
                    name="Webinar"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.whitePaper}
                    type="whitePaper"
                    name="White Paper"
                    filterLinkActive={filterLinkActive}
                  />
                </>
              ) : (
                <>
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.casiSession}
                    type="casiSession"
                    name="CASI Session"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.patientBrochure}
                    type="patientBrochure"
                    name="Patient Brochure"
                    filterLinkActive={filterLinkActive}
                  />
                  <ResourceFiltersLink
                    getLibraryUrl={getLibraryUrl}
                    count={count.patientEducation}
                    type="patientEducation"
                    name="Patient Education"
                    filterLinkActive={filterLinkActive}
                  />
                </>
              )}
            </ul>
          </li>
        </ul>
      </div>
      <div className={`${className} faceted-filter-shortcuts`}>
        {showClearFilters ? (
          <>
            <SecondaryButton text="Edit Filters" className="filters-btn" onClick={toggleClass} />
            <Link to="/research-education/library" className="filters-btn">
              Clear Filters
            </Link>
          </>
        ) : (
          <SecondaryButton text="Filter" className="filters-btn" onClick={toggleClass} />
        )}
      </div>
      {isActive && !isWide && <LibraryDimmer />}
    </>
  )
}

ResourceFilters.propTypes = {
  libraryType: PropTypes.oneOf(['public', 'professional']).isRequired,
  searchOptions: libraryPropTypes.searchOptions.isRequired,
  meta: libraryPropTypes.meta.isRequired,
}

ResourceFilters.defaultProps = {
  className: '',
}

ResourceFilters.propTypes = {
  className: PropTypes.string,
}

const StyledResourceFilters = styled(ResourceFilters)(({ theme }) => ({
  'p, ul, li, a': {
    fontFamily: gothamFamilies,
  },
  ul: {
    marginTop: '0',
    marginBottom: '1rem',
    ul: {
      marginBottom: '0',
    },
  },
  a: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:focus': {
      outline: '1px solid #fff !important',
      outlineOffset: '5px !important',
    },
  },
  button: {
    overflow: 'visible',
  },
  'input[type=text]': {
    outline: '0 !important',
    boxShadow: 'none !important',
    borderBottom: '1px solid #000 !important',
  },
  'input#query': {
    display: 'block',
    width: '100%',
    height: 'calc(1.5em + .75rem + 2px)',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: theme.colors.grayscale.white,
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    fontFamily: gothamFamilies,
    boxSizing: 'border-box',
  },
  '.filters-btn': {
    display: 'inline-block',
    fontSize: '.875rem',
    backgroundColor: '#54565a',
    border: '1px solid #b2b2b2',
    fontFamily: robotoCondensedFamilies,
    fontWeight: '500',
    letterSpacing: '.1em',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.colors.grayscale.white,
    borderRadius: '5px',
    padding: '.4em 1em',
    transition:
      'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    verticalAlign: 'middle',
    userSelect: 'none',
    '&:focus': {
      color: '#212529',
      boxShadow: '0 0 0 0.2rem rgba(222,222,223,.5)',
    },
    '&:hover': {
      color: '#212529',
      textDecoration: 'none',
    },
    '&:not(:disabled):not(.disabled)': {
      cursor: 'pointer',
      '&:active': {
        color: '#212529',
        backgroundColor: '#e6e6e6',
        borderColor: '#dfdfdf',
      },
    },
  },
  '&.faceted-filters': {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '0 16px',
    display: 'none',
    flexDirection: 'column',
    position: 'absolute',
    overflow: 'hidden',
    top: '0',
    height: '0',
    background: theme.colors.grayscale.white,
    zIndex: '10',
    lineHeight: '1.5',
    boxShadow: '1px 3px 7px 0.5px rgba(11, 11, 11, .21)',
    boxSizing: 'border-box',
    '.close-icon': {
      alignSelf: 'flex-end',
      width: '32px',
      padding: '8px',
      margin: '16px 0',
      boxSizing: 'border-box',
      img: {
        display: 'block',
        maxWidth: '100%',
      },
    },
    '.clear-filters': {
      marginBottom: '2rem',
      textAlign: 'center',
      lineHeight: '1.5',
    },
    '.filter-form': {
      '.form-group-filter': {
        display: 'flex',
        position: 'relative',
      },
      'input#query': {
        borderLeft: 'none',
        borderTop: 'none',
        borderRight: 'none',
        paddingRight: '40px',
      },
      '.submit-query': {
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
        border: '0',
        outline: '0',
        backgroundColor: 'transparent',
        padding: '8px 12px',
        cursor: 'pointer',
        color: '#a9a9a9',
      },
    },
    '.facets': {
      paddingLeft: '0',
      listStyle: 'none',
      marginTop: '1rem',
      '.facet-title': {
        textTransform: 'uppercase',
        fontWeight: '700',
        marginBottom: 5,
      },
    },
    '.facet-children': {
      listStyle: 'none',
      paddingLeft: '10px',
      paddingTop: '8px',
      lineHeight: 1.43,
      '.facet-child': {
        marginBottom: 10,
      },
      '.filter-link': {
        color: 'inherit',
        display: 'flex',
        alignItems: 'flex-start',
        position: 'relative',
        '&:hover': {
          color: '#e64b37',
          textDecoration: 'none',
        },
      },
      '.filter-link-visited': {
        fontWeight: '500',
        '&:hover': {
          textDecoration: 'line-through',
          color: '#e64b37',
        },
      },
      '.filter-link-icon': {
        marginRight: 5,
        marginTop: '0.1em',
      },
      '.filter-link-text': {
        flex: 1,
      },
      '.facet-count': {
        fontWeight: '300',
      },
    },
  },
  '&.drawer-open': {
    display: 'flex',
    height: 'auto',
  },
  '.close-icon': {
    float: 'right',
    fontSize: '1.5rem',
    fontWeight: '700',
    lineHeight: '1',
    color: '#000',
    textShadow: '0 1px 0 #fff',
    opacity: '.5',
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
    },
    '&:not(:disabled):not(.disabled):focus, :not(:disabled):not(.disabled):hover': {
      opacity: '.75',
    },
  },
  'button.close-icon': {
    padding: '0',
    backgroundColor: 'transparent',
    border: '0',
  },
  '.form-group-filter': {
    marginBottom: '1rem',
  },
  // unsetting these properties to fix position of filter icon
  '.text-input': {
    height: 'unset',
    marginBottom: 'unset',
  },
  '&.faceted-filter-shortcuts': {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0',
    // override SecondaryButton default styles
    button: {
      lineHeight: '1.5',
      height: 'auto',
    },
    '.filters-btn': {
      display: 'inline-block',
      margin: '0 16px',
      fontSize: '1rem',
    },
    'a.filters-btn': {
      backgroundColor: '#35363a',
      border: '1px solid #707070',
      '&:focus': {
        color: theme.colors.grayscale.white,
        boxShadow: '0 0 0 0.2rem rgba(83,84,88,.5)',
      },
      '&:not(:disabled):not(.disabled):active': {
        color: theme.colors.grayscale.white,
        backgroundColor: '#1d1d1f',
        borderColor: '#171719',
      },
    },
  },
  '@media(min-width: 432px)': {
    '&.faceted-filters': {
      maxWidth: '432px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  '@media(min-width: 768px)': {
    'a.filters-btn': {
      '&:hover': {
        backgroundColor: theme.colors.grayscale.white,
        color: '#000',
      },
    },
  },
  '@media(min-width: 992px)': {
    '&.faceted-filters': {
      position: 'relative',
      display: 'flex',
      height: '100%',
      flex: '0 0 25%',
      boxShadow: 'unset',
      top: 'unset',
      background: 'unset',
      zIndex: 'unset',
      maxWidth: 'unset',
      left: 'unset',
      transform: 'unset',
      padding: '16px 16px',
      '.close-icon': {
        display: 'none',
      },
      '.clear-filters': {
        textAlign: 'left',
      },
    },
    '&.faceted-filter-shortcuts': {
      display: 'none',
    },
  },
  '@media(min-width: 1200px)': {
    '&.faceted-filters': {
      padding: '16px 32px',
    },
  },
}))

export default StyledResourceFilters
