import { Subscribe } from '@react-rxjs/core'
import React, { useEffect } from 'react'

import { SanityDispensaryFeaturedProducts } from '../../../graphql/gatsby'
import useIsSsr from '../../../hooks/useIsSsr'
import { useResultWithLeftEffect } from '../../../hooks/useResultWithLeftEffect'
import { useSanityRoleBasedContent } from '../../../lib/sanity'
import {
  latestFeaturedProducts$,
  searchFeaturedProducts,
  useFeaturedProducts,
} from '../../../lib/sanity-dispensaries/featured-products'
import { constNull } from '../../../utils/constant'
import Spinner from '../../Spinner'
import * as Styled from './styled'

export interface DispensaryFeaturedProductsProps extends SanityDispensaryFeaturedProducts {
  className?: string
}

const pluckData = <T,>({ data }: { data: T }) => data

const DispensaryFeaturedProductsContent: React.FC = () => {
  const [, data] = useResultWithLeftEffect(useFeaturedProducts(), pluckData, constNull)

  if (!data || data.products.length === 0) {
    return null
  }

  const { title, products: featuredProducts } = data

  return (
    <>
      <h3>{title || 'Featured Products'}</h3>
      <Styled.ProductList>
        {featuredProducts.map((featuredProduct) => (
          <Styled.ProductBannerCard key={featuredProduct.sku} product={featuredProduct} />
        ))}
      </Styled.ProductList>
    </>
  )
}

const DispensaryFeaturedProducts: React.FC<DispensaryFeaturedProductsProps> = ({
  forceDefault,
  pageSectionStyles,
  roleBasedContent,
}) => {
  // Suspense not supported server-side yet
  const isSsr = useIsSsr()
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })

  const shouldDisplayContent = shouldDisplay && !isSsr

  useEffect(() => {
    if (shouldDisplayContent) {
      searchFeaturedProducts({ forceDefault: forceDefault ?? false })
    }
  }, [shouldDisplayContent, forceDefault])

  if (!shouldDisplayContent) {
    return null
  }

  return (
    <Styled.Container pageSectionStyles={pageSectionStyles}>
      <Subscribe source$={latestFeaturedProducts$} fallback={<Spinner loading />}>
        <DispensaryFeaturedProductsContent />
      </Subscribe>
    </Styled.Container>
  )
}

export default DispensaryFeaturedProducts
