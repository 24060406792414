import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import Modal from 'styled-react-modal'
import { Theme } from '../../../theme'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  color: ${({ theme }) => theme.colors.brand.gray};
  position: relative;
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  box-sizing: border-box;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
`

export const Column = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 12px;
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
  @media (min-width: 576px) {
    flex: 0 0 75%;
    max-width: 75%;
  }
  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
`

export const TeamMemberSelectable = styled.section`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.neutralGray.neutral200};
  box-shadow: 1px 3px 7px 0.5px rgba(11, 11, 11, 0.21);
  position: relative;
  flex: 1;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out 0s;
  &:hover {
    box-shadow: 1px 3px 7px 0.5px rgba(11, 11, 11, 0.4075);
  }
  &:focus {
    box-shadow: 1px 3px 7px 0.5px rgba(11, 11, 11, 0.4075);
    outline: 1px solid ${({ theme }) => theme.colors.neutralGray.neutral600};
  }
`

export const ScienceTeamMemberImage = styled(GatsbyImage)``

export const ImageAndHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  ${ScienceTeamMemberImage} {
    margin-right: 1rem;
  }
`

export const Header = styled.h2`
  margin-top: 1rem;
  margin-bottom: 0;
  flex: 1;
  min-width: 45%;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.component.textDark};
`

export const Title = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.055em;
  color: ${({ theme }) => theme.colors.brand.gray};
  display: block;
`

export const BiographyModal = Modal.styled`${({ theme }: { theme: Theme }) => css`
  background-color: ${theme.colors.grayscale.white};
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  height: fit-content;
  margin-top: 28px;
  max-height: calc(100% - 28px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  pointer-events: auto;
  top: 0;
  width: 100%;
  @media (min-width: 576px) {
    max-width: 500px;
  }
})`}`

export const ModalHeader = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dfe1e5;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`

export const ModalTitle = styled.div`
  margin-bottom: 0;
  line-height: 1.5;
`

export const ModalContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
`

export const CloseButton = styled.button`
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  background-color: transparent;
  border: 0;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grayscale.black};
  text-shadow: 0 1px 0 ${({ theme }) => theme.colors.grayscale.white};
  opacity: 0.5;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
  }
`
