import styled, { css } from 'styled-components'
import { SanityIconAndTextGridSmall } from '../../../graphql/gatsby'
import { pageSectionStylesFor } from '../styles'
import { headingFontSize } from '../../../theme/helpers'

export const Container = styled.div<Pick<SanityIconAndTextGridSmall, 'pageSectionStyles'>>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding: 3rem 2.25rem 3rem 2.25rem;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const IconGroup = styled.div<{ numEntries: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  padding-bottom: 1rem;
  justify-items: center;
  @media (min-width: 992px) {
    ${({ numEntries }) =>
      numEntries % 2 === 0
        ? css`
            grid-template-columns: repeat(2, 1fr);
          `
        : css`
            grid-template-columns: repeat(3, 1fr);
          `};
    ${({ numEntries }) =>
      numEntries === 1
        ? css`
            grid-template-columns: 1fr;
          `
        : css``};
  }
`

export const IconTitle = styled.h2`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
  min-width: 200px;
  text-align: left;
  @media (min-width: 992px) {
    min-width: unset;
  }
`

export const Icon = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin-left: 0.75rem;
`

export const IconWithTitle = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 1rem;
  position: relative;
  justify-content: center;
  min-width: 260px;
  @media (min-width: 992px) {
    justify-content: start;
  }
`
