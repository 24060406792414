import React from 'react'
import { SanityCtaCardSection } from '../../../graphql/gatsby'
import * as Styled from './styled'
import SanityLink from '../../global/SanityLink'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'

export type CtaCardSectionProps = SanityCtaCardSection

export const CtaCardSection: React.FC<CtaCardSectionProps> = ({
  title,
  linkText,
  roleBasedContent,
  link,
  pageSectionStyles,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!link?.target?.length || !shouldDisplay) {
    return null
  }

  return (
    <Styled.Container pageSectionStyles={pageSectionStyles}>
      <Styled.Card>
        <Styled.CardTitle>{title}</Styled.CardTitle>
        <Styled.CtaButton as={SanityLink} to={link}>
          {linkText}
        </Styled.CtaButton>
      </Styled.Card>
    </Styled.Container>
  )
}
