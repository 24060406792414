import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { graphql } from 'gatsby'
import React, { FC, useEffect, useMemo, useRef } from 'react'
import { TermsModalContent } from './TermsModalContent'
import { AllTermsProps, SetCurrentlyOpenTermsModal, TermsModalType } from './types'

export type TermsModalProps = AllTermsProps & {
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
  currentlyOpenTermsModal?: TermsModalType | null
  open: boolean
}

const termsModalTitle = ({
  currentlyOpenTermsModal,
}: {
  currentlyOpenTermsModal?: TermsModalType | null
}) => {
  switch (currentlyOpenTermsModal) {
    case 'privacyNotice':
      return 'PRIVACY NOTICE'
    case 'termsOfSale':
      return 'TERMS OF SALE'
    case 'termsOfUse':
    default:
      return 'TERMS OF USE'
  }
}

export const TermsModal: FC<TermsModalProps> = ({
  allTerms,
  open,
  currentlyOpenTermsModal,
  setCurrentlyOpenTermsModal,
}) => {
  const descriptionElementRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const currentlyOpenTerms = useMemo(
    () =>
      currentlyOpenTermsModal
        ? allTerms.find(
            (terms) => !!terms.identifier && terms.identifier === currentlyOpenTermsModal
          )
        : undefined,
    [allTerms, currentlyOpenTermsModal]
  )

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open, currentlyOpenTermsModal])

  const closeModal = () => setCurrentlyOpenTermsModal(null)

  return (
    <div>
      <Dialog
        aria-describedby="scroll-dialog-description"
        aria-labelledby="scroll-dialog-title"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={closeModal}
        open={open}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">
          {termsModalTitle({ currentlyOpenTermsModal })}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            component="section"
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {currentlyOpenTerms && (
              <TermsModalContent
                terms={currentlyOpenTerms}
                setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
                open
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const termsFragment = graphql`
  fragment allSanityTerms on Query {
    allSanityTerms(sort: { fields: _updatedAt, order: DESC }) {
      totalCount
      nodes {
        _type
        _id
        identifier
        title
        lastModified
        _rawContent(resolveReferences: { maxDepth: 100 })
      }
    }
  }
`

TermsModal.defaultProps = {
  currentlyOpenTermsModal: null,
}
