import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import ComingSoonImageImport from '../../../ComingSoonImage'
import SanityLink from '../../../global/SanityLink'

export const ImageWrapper = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  img {
    display: block;
  }
`

export const BlockMenuLinksListEntry = styled.li`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  @media (min-width: 432px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 7px 8px;
  }
  @media (min-width: 992px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: unset;
  &:before {
    content: '';
    display: block;
    padding-top: 80%;
  }
`

export const TextBlock = styled.div`
  margin: auto 0 auto 50%;
  padding: 0 5%;
`

export const TitleShortcardDeck = styled.h5`
  margin-bottom: 15px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 700;
  font-size: 1rem;
  text-transform: lowercase;
  color: ${(props) => props.theme.colors.component.textDark};
  line-height: 1.2;
  margin-top: 0;
  letter-spacing: normal;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`

export const TextShortcardDeck = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 500;
  font-size: 0.5rem;
  letter-spacing: 0.055em;
  color: ${({ theme }) => theme.colors.brand.gray};
  margin: 0;
  line-height: 1.5;
  @media (min-width: 768px) {
    font-size: 0.625rem;
  }
  @media (min-width: 992px) {
    font-size: 0.6875rem;
  }
`

export const Title = styled.h5`
  position: absolute;
  width: 80%;
  max-width: 80%;
  bottom: 25%;
  margin: 0 10%;
  z-index: 1;
  line-height: 1.1;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: ${(props) => props.theme.colors.component.textDark};
`

export const Text = styled.p`
  position: absolute;
  width: 80%;
  max-width: 80%;
  top: 75%;
  margin: 0 10%;
  padding-top: 1rem;
  z-index: 1;
  &,
  p {
    text-align: center;
  }
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.055em;
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.gray};
`

export const CardLink = styled(SanityLink)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  text-decoration: none;
  @media (min-width: 432px) {
    box-shadow: rgb(11 11 11 / 21%) 1px 3px 7px 0.5px;
    &:hover {
      box-shadow: rgb(11 11 11 / 50%) 1px 3px 7px 0.5px;
    }
  }
`

export const CardLinkShortcutDeck = styled(SanityLink)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background: ${({ theme }) => theme.colors.neutralGray.neutral200};
  box-shadow: 0 2px 4px 0.3px rgba(11, 11, 11, 0.15);
  text-decoration: none;
`

export const ShortcardDeckImage = styled.img`
  display: block;
  position: absolute;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`

export const ComingSoonImage = styled(ComingSoonImageImport)`
  display: block;
  position: absolute;
  margin-left: 5%;
  height: 65%;
  pointer-events: none;
  z-index: 1;
`
