import React from 'react'
import styled from 'styled-components'

import { pageSectionStylesFor } from '../styles'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'
import {
  SanityTable,
  SanityTableCell,
  SanityTableRow,
  SanityTableSection,
} from '../../../graphql/gatsby'

import BodyPortableText from '../BodyPortableText'
import * as Styled from './styled'

export interface TableCellProps extends SanityTableCell {
  className?: string
}

const TableCell: React.VFC<TableCellProps> = ({ className, colSpan, data, rowSpan }) => {
  if (!data) return null
  const isTableHeader = !!data.heading

  if (isTableHeader) {
    return (
      <Styled.TableHeaderCell className={className} colSpan={colSpan ?? 1} rowSpan={rowSpan ?? 1}>
        {data?.contents && <BodyPortableText blocks={data.contents} />}
      </Styled.TableHeaderCell>
    )
  }
  return (
    <Styled.TableCell className={className} colSpan={colSpan ?? 1} rowSpan={rowSpan ?? 1}>
      {data?.contents && <BodyPortableText blocks={data.contents} />}
    </Styled.TableCell>
  )
}

export interface TableRowProps extends SanityTableRow {
  className?: string
}

const TableRow: React.VFC<TableRowProps> = ({ className, cells }) => (
  <Styled.TableRow className={className}>
    {cells &&
      cells.map(
        (cell, index) =>
          cell?.data && (
            <TableCell
              colSpan={cell.colSpan}
              data={cell.data}
              rowSpan={cell.rowSpan}
              key={cell._key ?? index}
            />
          )
      )}
  </Styled.TableRow>
)

export interface TableProps extends SanityTable {
  className?: string
}

const Table: React.VFC<TableProps> = ({ className, rows }) => (
  <Styled.Table className={className}>
    <tbody>
      {rows &&
        rows.map(
          (row, index) => row?.cells && <TableRow cells={row.cells} key={row._key ?? index} />
        )}
    </tbody>
  </Styled.Table>
)

export interface TableSectionProps extends SanityTableSection {
  className?: string
}

const TableSection: React.VFC<TableSectionProps> = ({ className, roleBasedContent, table }) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!shouldDisplay || !table) {
    return null
  }
  return (
    <div className={className}>
      <Table rows={table?.rows} />
    </div>
  )
}

const StyledTableSection = styled(TableSection)`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  overflow-x: auto;
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export default StyledTableSection
