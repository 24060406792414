import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SanityHubSpotForm } from '../../graphql/gatsby'
import ThirdPartySandboxIFrame, {
  ThirdPartySandboxQueryParams,
} from '../global/ThirdPartySandboxIFrame'
import { pageSectionStylesFor } from './styles'

const hubSpotPortalId = process.env.GATSBY_HUBSPOT_PORTAL_ID

const Container = styled.div<Pick<SanityHubSpotForm, 'pageSectionStyles'>>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

type HubSpotFormProps = SanityHubSpotForm

const HubSpotForm: React.FC<HubSpotFormProps> = ({ formId, pageSectionStyles }) => {
  const params = useMemo(
    (): ThirdPartySandboxQueryParams | undefined =>
      formId && hubSpotPortalId
        ? {
            f: formId,
            p: hubSpotPortalId,
          }
        : undefined,
    [formId]
  )

  if (!params) {
    return null
  }

  return (
    <Container pageSectionStyles={pageSectionStyles}>
      <ThirdPartySandboxIFrame
        params={params}
        path="/hs/form.html"
        sandbox="allow-forms allow-scripts allow-same-origin"
        title="HubSpot Form"
      />
    </Container>
  )
}

export default HubSpotForm
