import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../global/Link'
import { getLibraryUrlGenerator, libraryPropTypes } from '../../../utils/libraryTools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const ResourcePagination = ({ className, searchOptions, meta }) => {
  const getLibraryUrl = getLibraryUrlGenerator(searchOptions)
  const { pagination: metaPagination } = meta
  const { totalPages, currentPage } = metaPagination
  const { pageNumbers, previousPage, nextPage } = useMemo(() => {
    const maxPages = 10
    const hasPages = totalPages > 0
    let startPage, endPage
    if (totalPages <= maxPages) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      if (currentPage <= 6) {
        startPage = 1
        endPage = maxPages
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }
    let pageNumbers = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i)
    const previousPage = hasPages && currentPage > 1 ? currentPage - 1 : null
    const nextPage = hasPages && currentPage !== totalPages ? currentPage + 1 : null
    return { pageNumbers, previousPage, nextPage }
  }, [currentPage, totalPages])
  return (
    <nav className={className} aria-label="Results navigation">
      <ul className="resource-pagination">
        {totalPages > 1 && (
          <>
            <li className={`resource-page-item ${!previousPage ? 'disabled' : ''}`}>
              <Link className="resource-page-link" to={getLibraryUrl({ page: previousPage })}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </Link>
            </li>
            {pageNumbers.map((pageNumber) => {
              return (
                <li
                  className={`resource-page-item ${currentPage === pageNumber ? 'active' : ''}`}
                  key={pageNumber}
                >
                  <Link className="resource-page-link" to={getLibraryUrl({ page: pageNumber })}>
                    {pageNumber}{' '}
                  </Link>
                </li>
              )
            })}
            <li className={`resource-page-item ${!nextPage ? 'disabled' : ''}`}>
              <Link className="resource-page-link" to={getLibraryUrl({ page: nextPage })}>
                <FontAwesomeIcon icon={faAngleRight} />
              </Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  )
}

ResourcePagination.defaultProps = {
  className: '',
}

ResourcePagination.propTypes = {
  className: PropTypes.string,
  searchOptions: libraryPropTypes.searchOptions.isRequired,
  meta: libraryPropTypes.meta.isRequired,
}

const StyledResourcePagination = styled(ResourcePagination)(({ theme }) => ({
  '.resource-pagination': {
    display: 'flex',
    paddingLeft: '0',
    listStyle: 'none',
    margin: '2rem 0',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '.resource-page-item': {
      margin: '0',
      '&.disabled': {
        opacity: '.25',
        '.resource-page-link': {
          cursor: 'default',
        },
      },
      '.resource-page-link:hover, &.active .resource-page-link': {
        borderBottomColor: theme.colors.brand.orange,
      },
      '.resource-page-link': {
        fontFamily: theme.fontFamilies.robotoCondensed,
        fontSize: '1.25rem',
        color: theme.colors.brand.orange,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'block',
        lineHeight: '2.4',
        backgroundColor: 'transparent',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: '2px solid transparent',
        '&:active': {
          outline: '1px solid #fff !important',
          outlineOffset: '5px !important',
        },
        '&:focus': {
          boxShadow: '0 0 0 .2rem rgba(230,75,55,.25)',
        },
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  '.resource-page-link': {
    position: 'relative',
    display: 'block',
    padding: '.5rem .75rem',
    marginLeft: '-1px',
    lineHeight: '1.25',
    color: theme.colors.grayscale.white,
    backgroundColor: theme.colors.grayscale.white,
    border: '1px solid #dee2e6',
    '&:hover': {
      zIndex: '2',
      color: '#d9d9d9',
      textDecoration: 'none',
      backgroundColor: '#e9ecef',
      borderColor: '#dee2e6',
    },
    '&:focus': {
      zIndex: '3',
      outline: '0',
      boxShadow: '0 0 0 .2rem rgba(255,255,255,.25)',
    },
  },
  '.resource-page-item:first-child .resource-page-link': {
    marginLeft: '0',
  },
  '.resource-page-item.active .resource-page-link': {
    zIndex: '3',
  },
  '.resource-page-item.disabled .resource-page-link': {
    pointerEvents: 'none',
  },
  '@media (min-width: 768px)': {
    '.resource-pagination': {
      margin: '3rem 0',
    },
  },
}))

export default StyledResourcePagination
