import React from 'react'

import { TermsModalTriggerLink } from './TermsModalTriggerLink'
import { TermsLinkComponent } from '../types'

export const TermsOfSaleLink: TermsLinkComponent = ({
  className,
  setCurrentlyOpenTermsModal,
  text,
}) => (
  <TermsModalTriggerLink
    className={className}
    modalType="termsOfSale"
    setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
  >
    {text}
  </TermsModalTriggerLink>
)

TermsOfSaleLink.defaultProps = {
  className: '',
  text: 'Terms of Sale',
}
