import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import rehype2react from 'rehype-react'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import styled from 'styled-components'
import unified from 'unified'
import { SanityGlobalMarkets } from '../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../utils/sanity/image'

const StyledContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 36px 32px;
  box-sizing: border-box;
  @media (min-width: 576px) {
    padding: 72px 48px;
  }
  @media (min-width: 768px) {
    padding: 64px;
  }
  @media (min-width: 992px) {
    padding: 88px 64px;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
`

const StyledImage = styled(GatsbyImage)``

const markdownProcessor = unified()
  .use(markdown)
  .use(remark2rehype)
  .use(rehype2react, { createElement: React.createElement })

export type GlobalMarketsProps = SanityGlobalMarkets & {
  className?: string
}

const GlobalMarkets: React.FC<GlobalMarketsProps> = ({ className, content, image }) => {
  const globalMarketsImage = getGatsbyImageDataForAsset(image, { width: 1200 })
  return (
    <div className={className}>
      <StyledContainer>
        <StyledRow>
          <StyledColumn>
            <>{content && markdownProcessor.processSync(content).result}</>
          </StyledColumn>
        </StyledRow>
        {globalMarketsImage && <StyledImage image={globalMarketsImage} alt="" />}
      </StyledContainer>
    </div>
  )
}

const StyledGlobalMarkets = styled(GlobalMarkets)`
  position: relative;
  color: ${({ theme }) => theme.colors.grayscale.white};
  p {
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-size: 12px;
    letter-spacing: 0.66px;
    line-height: 2;
    margin-bottom: 16px;
  }
  a {
    color: inherit;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.grayscale.white};
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.brand.red};
      border-bottom: 1px solid ${({ theme }) => theme.colors.brand.red};
    }
    &:active {
      outline: 1px solid ${({ theme }) => theme.colors.grayscale.white};
      outline-offset: 5px;
    }
  }
  &::before {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.colors.grayscale.white};
    padding-top: 2px;
    @media (min-width: 768px) {
      padding-top: 4px;
    }
    @media (min-width: 992px) {
      padding-top: 8px;
    }
  }
`

export default StyledGlobalMarkets
