import styled from 'styled-components'
import { Card } from '../BlockMenuLink/styled'

export const BlockMenuAdditionalShortcutsContainer = styled.nav`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 14px 16px;
  &.dark-blue-bg {
    background: #02162f;
    box-shadow: rgb(9 32 60) -2px -15px 15px 0px inset;
  }
  &.white-bg {
    background-color: ${({ theme }) => theme.colors.grayscale.white};
  }
  ${Card} {
    margin: 0;
    &:before {
      content: '';
      display: block;
      padding-top: 52.94%;
    }
  }
  @media (min-width: 576px) {
    padding: 23px 16px;
    ${Card} {
      a {
        box-shadow: 1px 3px 7px 0.5px rgba(11, 11, 11, 0.21);
        &:hover {
          box-shadow: 1px 3px 7px 0.5px rgba(11, 11, 11, 0.5);
        }
      }
    }
  }
  @media (min-width: 992px) {
    padding: 40px 16px;
  }
`

export const BlockMenuAdditionalShortcutsList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: -6.5px 0;
  padding: 0;
  @media (min-width: 432px) {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  @media (min-width: 576px) {
    margin-top: -14px;
    margin-bottom: -14px;
  }
  @media (min-width: 992px) {
    margin-left: -12px;
    margin-right: -12px;
  }
  && {
    li {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding: 6.5px 0;
      display: block;
    }
    @media (min-width: 432px) {
      li {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    @media (min-width: 576px) {
      li {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 14px 8px;
      }
    }
    @media (min-width: 992px) {
      li {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
`
