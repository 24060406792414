import { useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'

import { SearchExactParentOrChildSkuDocument } from '../../graphql/search'
import useLogAndCaptureError from '../../hooks/useLogAndCaptureError'
import { TokenContext } from '../../layouts/context'
import ProductBannerCard from '../products/banners/cards/ProductBannerCard'
import Spinner from '../Spinner'

type ProductDetails = {
  sku: string
  tagline: string
  shortDescription: string
}
export interface FeaturedProductEntriesProps {
  productsDetails: ProductDetails[]
}

export const FeaturedProductEntries: React.FC<FeaturedProductEntriesProps> = ({
  productsDetails,
}) => {
  const token = useContext(TokenContext)
  const productsSkus = productsDetails?.map((product) => product.sku)
  const { data, loading, error } = useQuery(SearchExactParentOrChildSkuDocument, {
    context: {
      token,
      uri: process.env.GATSBY_SEARCH_URL,
    },
    variables: {
      sku: productsSkus,
    },
    skip: !productsSkus,
  })
  useLogAndCaptureError(error)

  const products = data?.exact?.results
  const sortedProducts = useMemo(() => {
    const skuOrder = productsDetails.map((product) => product.sku)
    if (Array.isArray(products)) {
      return [...products].sort((a, b) => skuOrder.indexOf(a.sku) - skuOrder.indexOf(b.sku))
    }
  }, [products, productsDetails])

  if (loading) {
    return <Spinner loading />
  }

  if (!sortedProducts) return null

  return (
    <>
      {sortedProducts.map((product, index) => (
        <ProductBannerCard
          productDetails={productsDetails[index]}
          product={product}
          key={product.sku}
          analyticsEventAppContext="featured_products"
        />
      ))}
    </>
  )
}
