import styled, { css } from 'styled-components'
import Link from './Link'

export const buttonStyles = ({ height }: { height: number }) => css`
  // define variables used to center text vertically in elements other than "button"
  --btn-height: ${height}px;
  --btn-line-height: 16px;
  --btn-padding-y: calc((var(--btn-height) - var(--btn-line-height)) / 2);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.component.button};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  // use min-height to avoid clipped or off-centered text
  min-height: var(--btn-height);
  letter-spacing: 0.06em;
  line-height: var(--btn-line-height);
  // center text vertically
  padding-top: var(--btn-padding-y);
  padding-bottom: var(--btn-padding-y);
  // include default button styles for use with other element types (e.g. a)
  display: inline-block;
  text-align: center;
  text-decoration: none;
  &:disabled {
    cursor: not-allowed;
  }
  // Prevent square box around the button when it is clicked:
  &:focus {
    outline: unset;
  }
`

export const LinkPrimaryButton = styled(Link)`
  ${buttonStyles({ height: 50 })};
  background-color: ${(props) => props.theme.colors.component.link};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.grayscale.white};
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
  &:disabled {
    background-color: ${(props) => props.theme.colors.coolGray.cool400};
  }
`

export const LinkSecondaryButton = styled(Link)`
  ${buttonStyles({ height: 50 })};
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border: ${(props) => `1px solid ${props.theme.colors.brand.blue}`};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.brand.blue};
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
`

export const LinkButton = LinkSecondaryButton
