import styled from 'styled-components'
import { SanityButtonBlockSection } from '../../../graphql/gatsby'
import SanityLink from '../../global/SanityLink'
import { pageSectionStylesFor } from '../styles'

export const Container = styled.div<Pick<SanityButtonBlockSection, 'pageSectionStyles'>>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const ButtonBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

export const CtaButton = styled(SanityLink)`
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  color: ${({ theme }) => theme.colors.grayscale.white};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1.5;
  padding: 8px 32px;
  border-radius: 6px;
  max-width: 440px;
  margin: 1rem;
  min-height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  // set flex-basis to 0 to render buttons with equal width
  flex: 1 1 0;
  @media (min-width: 425px) {
    // force buttons to wrap until a reasonable width attained
    min-width: 280px;
  }
`
