import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SanityVideoSection } from '../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../lib/sanity/hooks/useSanityRoleBasedContent'
import { headingFontSize } from '../../theme/helpers'
import ThirdPartySandboxIFrame, {
  ThirdPartySandboxQueryParams,
} from '../global/ThirdPartySandboxIFrame'
import { pageSectionStylesFor, textStylesFor } from './styles'

const StyledHeading = styled.h2<Pick<SanityVideoSection, 'textStyles'>>`
  text-align: ${({ textStyles }) => textStyles?.textAlign ?? 'left'};
  font-size: ${({ theme }) => headingFontSize({ size: 3, theme })};
  margin-bottom: 2rem;
`

export interface VideoSectionProps extends SanityVideoSection {
  className?: string
}

const VideoSection: React.FC<VideoSectionProps> = ({
  className,
  textStyles,
  title,
  roleBasedContent,
  wistiaShareUrl,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })

  // TODO: make configurable in Sanity
  const aspectRatio = '16:9'

  const params = useMemo(
    (): ThirdPartySandboxQueryParams | undefined =>
      wistiaShareUrl
        ? {
            r: aspectRatio,
            w: wistiaShareUrl,
          }
        : undefined,
    [wistiaShareUrl]
  )

  if (!shouldDisplay || !params) return null

  return (
    <div className={className}>
      {title && <StyledHeading textStyles={textStyles}>{title}</StyledHeading>}
      <ThirdPartySandboxIFrame
        allowFullScreen
        params={params}
        path="/wistia/index.html"
        sandbox="allow-scripts allow-same-origin"
        title="Wistia Video"
      />
    </div>
  )
}

const StyledVideoSection = styled(VideoSection)`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  ${({ textStyles }) => textStylesFor(textStyles)};
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export default StyledVideoSection
