import styled, { css } from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.colors.brand.gray};
  position: relative;
  text-align: left;
  line-height: 1.5;
  &:before {
    content: '';
    display: block;
    padding-top: 0.125rem;
    background-color: ${({ theme }) => theme.colors.grayscale.white};
  }
  @media (min-width: 768px) {
    &:before {
      padding-top: 0.25rem;
    }
  }
  @media (min-width: 992px) {
    &:before {
      padding-top: 0.5rem;
    }
  }
`

export const HeadingContainer = styled.header`
  padding: 2rem 2.25rem 1rem 2.25rem;
  @media (min-width: 768px) {
    padding: 3rem 4.5rem 1rem;
  }
  @media (min-width: 992px) {
    padding: 4rem 5.5rem 1rem;
  }
`

const headingShared = css`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
`
const heading1Shared = css`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 1.625rem;
`

export const HeadingTitle = styled.h1`
  ${headingShared};
  ${heading1Shared};
  color: ${({ theme }) => theme.colors.brandBlue.dark300};
`

export const LastModified = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 400;
  font-size: 0.6875rem;
  letter-spacing: 0.055em;
  line-height: 2;
  @media (min-width: 768px) {
    font-size: 0.75rem;
  }
`

export const Content = styled(BlockContent)`
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  em {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    padding: 0 4.5rem 3rem;
  }
  @media (min-width: 992px) {
    padding: 0 5.5rem 4rem;
    column-count: 2;
    column-gap: 7.5%;
  }
`

export const Heading1 = styled.h1`
  ${headingShared};
  ${heading1Shared};
`
export const Heading2 = styled.h2`
  ${headingShared};
  font-size: 0.9375rem;
  p ~ & {
    margin-top: 2rem;
  }
`
export const Heading3 = styled.h3`
  ${headingShared};
  font-size: 0.9375rem;
`
export const Heading4 = styled.h4`
  ${headingShared};
  font-size: 0.9375rem;
`
export const Heading5 = styled.h5`
  ${headingShared};
`
export const Heading6 = styled.h6`
  ${headingShared};
`

const textElementShared = css`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 400;
  font-size: 0.6875rem;
  letter-spacing: 0.055em;
  line-height: 2;
  @media (min-width: 768px) {
    font-size: 0.75rem;
  }
`

export const Blockquote = styled.blockquote`
  ${textElementShared};
`

export const Paragraph = styled.p`
  ${textElementShared};
  margin-top: 0;
  margin-bottom: 1rem;
  @media (min-width: 992px) {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const List = styled.ul`
  ${textElementShared};
  padding-left: 1.5em;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  padding-inline-start: 40px;
  p ~ & {
    margin-top: -0.5rem;
  }
`

export const Anchor = styled.a`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: inherit;
  color: ${({ theme }) => theme.colors.brand.orange};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.brand.orange}66;
  background-color: transparent;
  &:hover {
    text-decoration-color: inherit;
  }
  &:focus {
    outline: 1px solid #fff !important;
    outline-offset: 5px !important;
  }
`
