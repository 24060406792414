import BlockContent from '@sanity/block-content-to-react'
import React, { useState } from 'react'
import {
  Maybe,
  SanityBlock,
  SanityScienceTeamGrid,
  SanityTeamMemberScience,
} from '../../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'

import * as Styled from './styled'

export interface ScienceTeamMemberBioProps {
  name: string
  biography?: Maybe<Maybe<SanityBlock>[]>
  show: boolean
  handleHideBio: () => void
}

const ScienceTeamMemberBio: React.FC<ScienceTeamMemberBioProps> = ({
  name,
  biography,
  show,
  handleHideBio,
}) => {
  return (
    <Styled.BiographyModal
      isOpen={show}
      onBackgroundClick={handleHideBio}
      onEscapeKeydown={handleHideBio}
    >
      <Styled.ModalHeader>
        <Styled.ModalTitle>{name}</Styled.ModalTitle>
        <Styled.CloseButton onClick={handleHideBio}>
          <span aria-hidden="true">×</span>
        </Styled.CloseButton>
      </Styled.ModalHeader>
      <Styled.ModalContent>{biography && <BlockContent blocks={biography} />}</Styled.ModalContent>
    </Styled.BiographyModal>
  )
}

export type ScienceTeamMemberProps = SanityTeamMemberScience

const ScienceTeamMember: React.FC<ScienceTeamMemberProps> = ({
  name,
  degrees,
  title,
  photo,
  biography,
}) => {
  const [showBio, setShowBio] = useState(false)

  const handleHideBio = () => setShowBio(false)
  const handleShowBio = () => setShowBio(true)
  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setShowBio(true)
    }
  }

  const image = getGatsbyImageDataForAsset(photo, {
    width: 96,
    height: 120,
  })

  return (
    <Styled.Column>
      <Styled.TeamMemberSelectable tabIndex={0} onClick={handleShowBio} onKeyPress={handleKeyPress}>
        <Styled.ImageAndHeader>
          {image && <Styled.ScienceTeamMemberImage image={image} loading="eager" alt="" />}
          <Styled.Header>
            {name ? `${name}` : ''}
            {degrees ? `, ${degrees}` : ''}
            {title && <Styled.Title>{title}</Styled.Title>}
          </Styled.Header>
        </Styled.ImageAndHeader>
      </Styled.TeamMemberSelectable>
      <ScienceTeamMemberBio
        name={name || ''}
        biography={biography || undefined}
        show={showBio}
        handleHideBio={handleHideBio}
      />
    </Styled.Column>
  )
}

export type ScienceTeamGridProps = SanityScienceTeamGrid

export const ScienceTeamGrid: React.FC<ScienceTeamGridProps> = ({ members }) => {
  if (!members) {
    return null
  }
  return (
    <Styled.Container>
      <Styled.Row>
        {members.map((member, index) => {
          return member && <ScienceTeamMember key={index} {...member} />
        })}
      </Styled.Row>
    </Styled.Container>
  )
}
