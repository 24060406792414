import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useMemo } from 'react'

import { SanityFeaturedProductsBanner } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity'
import { FeaturedProductEntries } from '../FeaturedProductEntries'
import { FeaturedProductsSlider } from '../FeaturedProductsSlider'
import * as Styled from './styled'

const FeaturedProductsBanner: React.FC<SanityFeaturedProductsBanner> = ({
  title,
  subtitle,
  products,
  roleBasedContent,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  const productsDetails = useMemo(
    () =>
      products
        ?.map((upsellProduct) => {
          return {
            sku: upsellProduct?.sku || '',
            tagline: upsellProduct?.tagline || '',
            shortDescription: upsellProduct?.shortDescription || '',
          }
        })
        .filter(Boolean) || [],
    [products]
  )

  const isMobile = useMediaQuery('(max-width:1080px)')

  if (!shouldDisplay || !productsDetails) {
    return null
  }

  return (
    <Styled.Background>
      <Styled.Container>
        <Styled.Title>{title ?? 'Featured Products'}</Styled.Title>
        {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
        {!isMobile && <FeaturedProductsSlider productsDetails={productsDetails} />}
        {isMobile && (
          <Styled.ScrollableContainer>
            <FeaturedProductEntries productsDetails={productsDetails} />
          </Styled.ScrollableContainer>
        )}
      </Styled.Container>
    </Styled.Background>
  )
}

export default FeaturedProductsBanner
