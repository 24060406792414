import { bind, Subscribe } from '@react-rxjs/core'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React from 'react'
import { of, switchMap } from 'rxjs'

import { SanityDispensaryHero } from '../../../graphql/gatsby'
import {
  Maybe,
  SanityHeroImageOptionDocument,
  SanityHeroTextOptionDocument,
} from '../../../groq/sanity-dispensaries/types'
import useIsSsr from '../../../hooks/useIsSsr'
import { useLogAndCaptureLeftResult } from '../../../hooks/useLogAndCaptureLeftResult'
import { dispensaryImageUrlFor, useSanityRoleBasedContent } from '../../../lib/sanity'
import { dispensariesSanityConfig } from '../../../lib/sanity/config'
import {
  dispensariesSiteSettingsCdn$,
  dispensaryHomePageCdn$,
} from '../../../lib/sanity-dispensaries/query'
import { latestDispensarySlug$ } from '../../../lib/sanity-dispensaries/state'
import { mapRight } from '../../../utils/rx/operators'
import Spinner from '../../Spinner'
import * as Styled from './styled'

export interface DispensaryHeroProps extends SanityDispensaryHero {
  className?: string
}

const defaultHero$ = dispensariesSiteSettingsCdn$.pipe(
  mapRight((siteSettingsResult) => ({
    _tag: 'Right' as const,
    data: {
      heroImage: siteSettingsResult.data?.defaultDispensaryHeroImage,
      heroText: siteSettingsResult.data?.defaultDispensaryHeroText,
    },
  }))
)

const dispensaryHero$ = dispensaryHomePageCdn$.pipe(
  mapRight((dispensaryResult) => ({
    _tag: 'Right' as const,
    data: {
      heroImage: dispensaryResult.data?.heroImage,
      heroText: dispensaryResult.data?.heroText,
    },
  }))
)

const dispensaryHeroWithDefault$ = dispensaryHero$.pipe(
  switchMap((dispensaryHeroResult) =>
    dispensaryHeroResult._tag === 'Left' ||
    (dispensaryHeroResult.data.heroImage && dispensaryHeroResult.data.heroText)
      ? of(dispensaryHeroResult)
      : defaultHero$.pipe(
          mapRight((defaultHeroResult) => ({
            _tag: 'Right' as const,
            data: {
              heroImage: dispensaryHeroResult.data.heroImage ?? defaultHeroResult.data.heroImage,
              heroText: dispensaryHeroResult.data.heroText ?? defaultHeroResult.data.heroText,
            },
          }))
        )
  )
)

const [useDispensaryOrDefaultHero] = bind((forceDefault: boolean) =>
  forceDefault
    ? defaultHero$
    : latestDispensarySlug$.pipe(
        switchMap((dispensarySlug) => (!dispensarySlug ? defaultHero$ : dispensaryHeroWithDefault$))
      )
)

const HeroImageAsCallToAction: React.FC<{ heroImage: SanityHeroImageOptionDocument }> = ({
  heroImage,
}) => {
  const imageData = getGatsbyImageData(
    heroImage.image,
    {
      width: 1280,
      placeholder: 'blurred',
    },
    dispensariesSanityConfig
  )
  return (
    <Styled.CallToActionImageLink to="/products" aria-label="Shop Now">
      {imageData && <Styled.CallToActionImage image={imageData} alt="" />}
    </Styled.CallToActionImageLink>
  )
}

const HeroImageWithTextCallToAction: React.FC<{
  heroImage: SanityHeroImageOptionDocument
  heroText: Maybe<SanityHeroTextOptionDocument> | undefined
}> = ({ heroImage, heroText }) => (
  <Styled.HeroImageWithTextCallToAction>
    <picture>
      <source
        srcSet={`${
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(600)
            .height(300)
            .dpr(1)
            .fit('max')
            .auto('format')
            .url() || ''
        }, ${
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(600)
            .height(300)
            .dpr(2)
            .fit('max')
            .auto('format')
            .url() || ''
        } 2x`}
        media="(max-width: 600px)"
      />
      <source
        srcSet={`${
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(800)
            .height(300)
            .dpr(1)
            .fit('max')
            .auto('format')
            .url() || ''
        }, ${
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(800)
            .height(300)
            .dpr(2)
            .fit('max')
            .auto('format')
            .url() || ''
        } 2x`}
        media="(max-width: 800px)"
      />
      <source
        srcSet={`${
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(1280)
            .height(300)
            .dpr(1)
            .fit('max')
            .auto('format')
            .url() || ''
        }, ${
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(1280)
            .height(300)
            .dpr(2)
            .fit('max')
            .auto('format')
            .url() || ''
        } 2x`}
        media="(min-width: 1280px)"
      />
      <Styled.Image
        src={
          dispensaryImageUrlFor({
            _ref: heroImage.image.asset._ref,
          })
            .width(1280)
            .height(300)
            .dpr(1)
            .fit('max')
            .auto('format')
            .url() || ''
        }
      />
    </picture>
    <Styled.InnerContainer>
      {heroText?.text && (
        <Styled.Paragraph $textColor={heroImage.textColor}>{heroText.text}</Styled.Paragraph>
      )}
      {heroText?.ctaText && (
        <Styled.Button
          to="/products"
          $ctaBackgroundColor={heroImage.ctaBackgroundColor}
          $ctaTextColor={heroImage.ctaTextColor}
        >
          {heroText.ctaText}
        </Styled.Button>
      )}
    </Styled.InnerContainer>
  </Styled.HeroImageWithTextCallToAction>
)

const DispensaryHeroContent: React.FC<{ forceDefault: boolean }> = ({ forceDefault }) => {
  const heroResult = useDispensaryOrDefaultHero(forceDefault)

  useLogAndCaptureLeftResult(heroResult)

  const heroImage = heroResult._tag === 'Right' ? heroResult.data.heroImage : undefined
  const heroText = heroResult._tag === 'Right' ? heroResult.data.heroText : undefined

  return !heroImage ? null : heroImage.renderEntireImageAsCta ? (
    <HeroImageAsCallToAction heroImage={heroImage} />
  ) : (
    <HeroImageWithTextCallToAction heroImage={heroImage} heroText={heroText} />
  )
}

const DispensaryHero: React.FC<DispensaryHeroProps> = ({ forceDefault, roleBasedContent }) => {
  // Suspense not supported server-side yet
  const isSsr = useIsSsr()
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })

  if (!shouldDisplay || isSsr) {
    return null
  }

  return (
    <Styled.DispensaryHero data-cy="dispensary-hero">
      <Subscribe fallback={<Spinner loading />}>
        <DispensaryHeroContent forceDefault={forceDefault ?? false} />
      </Subscribe>
    </Styled.DispensaryHero>
  )
}

export default DispensaryHero
