import React from 'react'

import { SanityProductSpotlight, SanitySpotlightProduct } from '../../../graphql/gatsby'
import { imageUrlFor } from '../../../lib/sanity'
import SanityLink from '../../global/SanityLink'
import * as Styled from './styled'

export type ProductSpotlightProps = SanityProductSpotlight

export type SpotlightProductProps = SanitySpotlightProduct

const SpotlightProduct: React.VFC<SpotlightProductProps> = ({
  productTitle,
  productDescription,
  productImage,
  cta,
}) => {
  const productImageUrlForWidth = (width: number) =>
    (productImage &&
      imageUrlFor(productImage)
        .width(width)
        // aspect ratio 1:1
        .height(width)
        .auto('format')
        .url()) ??
    ''
  const productImageSrcSetEntryForWidth = (width: number) =>
    productImageUrlForWidth ? `${productImageUrlForWidth(width)} ${width}w` : ''

  const productImageDefaultSrc = productImageUrlForWidth(300)
  const productImageSrcSet = [
    productImageSrcSetEntryForWidth(200),
    productImageSrcSetEntryForWidth(300),
    productImageSrcSetEntryForWidth(400),
    productImageSrcSetEntryForWidth(600),
    productImageSrcSetEntryForWidth(800),
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <Styled.Column>
      <Styled.Product>
        <Styled.ImageAndText>
          <Styled.ProductImageWrapper>
            {productImageDefaultSrc && (
              <Styled.ProductImage
                alt={productTitle ?? ''}
                decoding="async"
                loading="lazy"
                sizes="(max-width: 424px) 175px, (max-width: 767px) 200px, (max-width: 991px) 300px, (max-width: 1439px) 190px, 285px"
                src={productImageDefaultSrc}
                srcSet={productImageSrcSet}
                width="300"
                height="300"
              />
            )}
          </Styled.ProductImageWrapper>
          <Styled.Text>
            {productTitle && <Styled.ProductTitle>{productTitle}</Styled.ProductTitle>}
            {productDescription && (
              <Styled.ProductDescription>{productDescription}</Styled.ProductDescription>
            )}
            {cta && cta.link && cta.link.target && cta.link.target.length > 0 && (
              <Styled.Cta as={SanityLink} to={cta.link}>
                {cta.title}
              </Styled.Cta>
            )}
          </Styled.Text>
        </Styled.ImageAndText>
      </Styled.Product>
    </Styled.Column>
  )
}

export const ProductSpotlight: React.VFC<ProductSpotlightProps> = React.memo(
  ({ backgroundStyles, title, description, products }) => {
    if (!products) {
      return null
    }
    return (
      <Styled.ProductSpotlight $backgroundStyles={backgroundStyles}>
        {title && <Styled.Header>{title}</Styled.Header>}
        {description && <Styled.Description>{description}</Styled.Description>}
        <Styled.Row>
          {products.map((spotlightProduct, index) => {
            return (
              spotlightProduct && (
                <SpotlightProduct {...spotlightProduct} key={spotlightProduct._key ?? index} />
              )
            )
          })}
        </Styled.Row>
      </Styled.ProductSpotlight>
    )
  }
)

ProductSpotlight.displayName = 'ProductSpotlight'
