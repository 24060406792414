import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { gothamFamilies } from '@designsforhealth/dfh-react-components'
import { libraryPropTypes } from '../../../utils/libraryTools'
import LibraryIntroMessage from './LibraryIntroMessage'
import ResourceLink from './ResourceLink'
import ResourcePagination from './ResourcePagination'

const ResourceList = ({ className, libraryType, searchOptions, meta, resources }) => {
  return (
    <div className={`${className} faceted-list`}>
      <div className="resource-row">
        <div
          className={classNames('resource-col-intro-message', 'display-none', 'display-lg-block')}
        >
          <LibraryIntroMessage libraryType={libraryType} />
        </div>
      </div>
      <div className="resource-row">
        {resources &&
          resources.map((resource) => (
            <div className="resource-col" key={resource._id}>
              <article className="faceted-resource">
                <ResourceLink resource={resource} />
              </article>
            </div>
          ))}
      </div>
      <ResourcePagination meta={meta} searchOptions={searchOptions} />
    </div>
  )
}

ResourceList.defaultProps = {
  className: '',
}

ResourceList.propTypes = {
  className: PropTypes.string,
  libraryType: PropTypes.oneOf(['public', 'professional']).isRequired,
  searchOptions: libraryPropTypes.searchOptions.isRequired,
  meta: libraryPropTypes.meta.isRequired,
  resources: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const StyledResourceList = styled(ResourceList)({
  '.resource-col': {
    position: 'relative',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    flex: '0 0 50%',
    maxWidth: '50%',
    boxSizing: 'border-box',
  },
  '.resource-col-intro-message': {
    flex: '0 0 100%',
    maxWidth: '100%',
    position: 'relative',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
    p: {
      lineHeight: '1.5',
    },
  },
  '&.faceted-list': {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '16px 16px',
    boxSizing: 'border-box',
    '.resource-row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-8px',
      marginRight: '-8px',
      marginTop: '-8px',
      marginBottom: '-8px',
    },
    '.resource-row~.resource-row': {
      paddingTop: '8px',
    },
    '.resource-row>[class*=resource-col]': {
      position: 'relative',
      width: '100%',
      minHeight: '1px',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
      boxSizing: 'border-box',
    },
    '.faceted-resource': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      fontFamily: gothamFamilies,
    },
  },
  '@media(min-width: 576px)': {
    '.resource-col': {
      flex: '0 0 33.3%',
      maxWidth: '33.3%',
    },
    '&.faceted-list': {
      '.resource-row': {
        marginTop: '-12px',
        marginBottom: '-12px',
      },
      '.resource-row~.resource-row': {
        paddingTop: '12px',
      },
      '.resource-row>[class*=resource-col]': {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
    },
  },
  '@media(min-width: 768px)': {
    '&.faceted-list': {
      '.resource-row': {
        marginLeft: '-12px',
        marginRight: '-12px',
      },
      '.resource-row>[class*=resource-col]': {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
    },
  },
  '@media(min-width: 992px)': {
    '&.faceted-list': {
      height: '100%',
      flex: '0 0 75%',
      maxWidth: '75%',
      '.resource-row': {
        marginTop: '-16px',
        marginBottom: '-16px',
      },
      '.resource-row~.resource-row': {
        paddingTop: '16px',
      },
      '.resource-row>[class*=resource-col]': {
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
  },
  '@media(min-width: 1200px)': {
    '.resource-col': {
      flex: '0 0 25%',
      maxWidth: '25%',
    },
    '&.faceted-list': {
      padding: '16px 32px',
    },
  },
})

export default StyledResourceList
