import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { SanityInstapage } from '../../graphql/gatsby'
import { usePageUrl } from '../../lib/pages'
import { normalizeLink } from '../../lib/pages/links'
import { OnInstapageNavigateMessage } from '../../lib/third-party-sandbox'
import ThirdPartySandboxIFrame, {
  ThirdPartySandboxQueryParams,
} from '../global/ThirdPartySandboxIFrame'
import { pageSectionStylesFor } from './styles'

const Container = styled.div<Pick<SanityInstapage, 'pageSectionStyles'>>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
`

const Instapage: React.FC<SanityInstapage> = ({ instapagePath, pageSectionStyles }) => {
  const { navigate } = usePageUrl()

  const params = useMemo((): ThirdPartySandboxQueryParams | undefined => {
    const pathParameter =
      instapagePath && instapagePath.startsWith('/') ? instapagePath.substring(1) : instapagePath
    return pathParameter ? { p: pathParameter } : undefined
  }, [instapagePath])

  const onNavigate = useCallback(
    ({ href, target }: OnInstapageNavigateMessage['data']) => {
      const link = normalizeLink(href, { target })
      if (link.type === 'gatsby') {
        navigate(link.to)
      } else if (link.href) {
        window.location.href = link.href
      } else {
        console.warn('[instapage] onNavigate: no href for link', link)
      }
    },
    [navigate]
  )

  if (!params) {
    return null
  }

  return (
    <Container pageSectionStyles={pageSectionStyles}>
      <ThirdPartySandboxIFrame
        params={params}
        path="/instapage/index.html"
        sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
        title="Instapage"
        onInstapageNavigateMessage={onNavigate}
      />
    </Container>
  )
}

export default Instapage
