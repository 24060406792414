import BannerWithArticle from './BannerWithArticle'
import { BannerWithText } from './BannerWithText'
import BasicArticle from './BasicArticle'
import BasicTextSection from './BasicTextSection'
import { BlockMenu } from './block-menu/BlockMenu'
import { BlockMenuAdditionalShortcuts } from './block-menu/BlockMenuAdditionalShortcuts'
import { BlockMenuCta } from './block-menu-cta/BlockMenuCta'
import { ButtonBlockSection } from './ButtonBlockSection'
import Carousel from './carousel/Carousel'
import { ComponentNotFound } from './ComponentNotFound'
import { CtaBlockSection } from './CtaBlockSection'
import { CtaCardSection } from './CtaCardSection'
import DispensaryBio from './DispensaryBio'
import DispensaryFeaturedProducts from './DispensaryFeaturedProducts'
import DispensaryHero from './DispensaryHero'
import FeaturedProductsBanner from './FeaturedProductsBanner'
import FeaturedProductsCarousel from './FeaturedProductsCarousel'
import GlobalMarkets from './GlobalMarkets'
import HubSpotForm from './HubSpotForm'
import { IconAndTextGrid } from './IconAndTextGrid'
import { IconAndTextGridSmall } from './IconAndTextGridSmall'
import ImageAndTextSection from './ImageAndTextSection'
import Instapage from './Instapage'
import { LeadershipGrid } from './LeadershipGrid'
import Library from './library/Library'
import LogoDividerSection from './LogoDividerSection'
import { Notification } from './Notification'
import PageHero from './PageHero'
import { ProductSpotlight } from './ProductSpotlight'
import RichArticle from './RichArticle'
import { ScienceTeamGrid } from './ScienceTeamGrid'
import SpacerSection from './SpacerSection'
import TableSection from './TableSection'
import { TermsSection } from './TermsSection'
import VideoSection from './VideoSection'

const ComponentList = {
  bannerWithArticle: BannerWithArticle,
  bannerWithText: BannerWithText,
  basicArticle: BasicArticle,
  basicTextSection: BasicTextSection,
  blockMenu: BlockMenu,
  blockMenuCta: BlockMenuCta,
  blockMenuAdditionalShortcuts: BlockMenuAdditionalShortcuts,
  buttonBlockSection: ButtonBlockSection,
  carousel: Carousel,
  ctaBlockSection: CtaBlockSection,
  ctaCardSection: CtaCardSection,
  dispensaryBio: DispensaryBio,
  dispensaryFeaturedProducts: DispensaryFeaturedProducts,
  dispensaryHero: DispensaryHero,
  featuredProductsBanner: FeaturedProductsBanner,
  featuredProductsCarousel: FeaturedProductsCarousel,
  globalMarkets: GlobalMarkets,
  hubSpotForm: HubSpotForm,
  iconAndTextGrid: IconAndTextGrid,
  iconAndTextGridSmall: IconAndTextGridSmall,
  imageAndTextSection: ImageAndTextSection,
  instapage: Instapage,
  leadershipGrid: LeadershipGrid,
  library: Library,
  logoDividerSection: LogoDividerSection,
  notification: Notification,
  pageHero: PageHero,
  productSpotlight: ProductSpotlight,
  richArticle: RichArticle,
  scienceTeamGrid: ScienceTeamGrid,
  spacerSection: SpacerSection,
  styleIconAndTextGrid: IconAndTextGrid,
  tableSection: TableSection,
  termsSection: TermsSection,
  videoSection: VideoSection,
}

const SanityPageComponents = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound(type)
  }
  return ComponentList[type]
}

export default SanityPageComponents
