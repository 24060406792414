import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import BlockContent from '@sanity/block-content-to-react'
import { BgImage } from 'gbimage-bridge'
import React from 'react'
import styled, { css } from 'styled-components'
import { SanityBannerWithArticle } from '../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../utils/sanity/image'
import SanityLink from '../global/SanityLink'
import { textStylesFor } from './styles'

type StyledColumnProps = Pick<SanityBannerWithArticle, 'textPosition' | 'textStyles'>

export const StyledBgImage = styled(BgImage)`
  width: 100%;
  height: 465px;
  &:before &:after {
    background-position: unset !important;
  }
  @media (min-width: 768px) {
    &:before &:after {
      background-position: center top !important;
    }
  }
`

export const StyledBanner = styled.div`
  position: relative;
  color: #fff;
`

export const StyledContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 36px 32px;
  @media (min-width: 576px) {
    padding: 72px 48px;
  }
  @media (min-width: 768px) {
    padding: 64px 64px;
  }
`

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`

export const StyledColumn = styled.div<StyledColumnProps>`
  width: 100%;
  min-height: 1px;
  padding: 0;
  ${({ textPosition, textStyles }) => {
    const positionStyling = () => {
      switch (textPosition) {
        case 'center':
          return css`
            margin: auto;
          `
        case 'right':
          return css`
            margin-left: auto;
          `
        case 'left':
        default:
          return css``
      }
    }
    return [...textStylesFor(textStyles), ...positionStyling()]
  }};
  @media (min-width: 576px) {
    flex: 0 0 66.6%;
    max-width: 66.6%;
  }
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
`

export const StyledH2 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 1em;
`

export const StyledBlockContent = styled(BlockContent)<{
  blocks: unknown[] | unknown
}>`
  p {
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.055em;
    line-height: 2;
    margin-top: -0.5rem;
  }
`

export const StyledCta = styled.div`
  margin-top: 1em;
`

export const StyledCtaButton = styled(PrimaryButton)`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.grayscale.white};
  font-weight: 500;
  letter-spacing: 0.1em;
  && {
    --btn-height: 40px;
  }
  @media (min-width: 768px) {
    font-size: 0.9375rem;
  }
  @media (min-width: 992px) {
    font-size: 1rem;
  }
`

export interface BannerWithArticleProps extends SanityBannerWithArticle {
  className?: string
}

const BannerWithArticle: React.FC<BannerWithArticleProps> = ({
  className,
  backgroundImage,
  articleTitle,
  articleContent,
  textPosition,
  textStyles,
  cta,
}) => {
  const bgImageData = getGatsbyImageDataForAsset(backgroundImage, {
    width: 1280,
    placeholder: 'blurred',
  })
  return (
    <div className={className}>
      {bgImageData && (
        <StyledBgImage image={bgImageData} alt="">
          <StyledBanner>
            <StyledContainer>
              <StyledRow>
                <StyledColumn {...{ textPosition, textStyles }}>
                  {articleTitle && <StyledH2>{articleTitle}</StyledH2>}
                  {articleContent && <StyledBlockContent blocks={articleContent} />}
                  {cta && cta.link && cta.link.target && cta.link.target.length > 0 && (
                    <StyledCta>
                      <StyledCtaButton as={SanityLink} to={cta.link}>
                        {cta.title}
                      </StyledCtaButton>
                    </StyledCta>
                  )}
                </StyledColumn>
              </StyledRow>
            </StyledContainer>
          </StyledBanner>
        </StyledBgImage>
      )}
    </div>
  )
}

BannerWithArticle.defaultProps = {
  className: '',
}

export default BannerWithArticle
