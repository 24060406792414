import React from 'react'
import PropTypes from 'prop-types'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import styled from 'styled-components'
import unified from 'unified'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import rehype2react from 'rehype-react'
import clientConfig from '../../../client-config'
import { ComponentNotFound } from './ComponentNotFound'
import Link from '../global/Link'

const markdownProcessor = unified()
  .use(markdown)
  .use(remark2rehype)
  .use(rehype2react, { createElement: React.createElement })

const RichArticleTextSection = ({ content }) => {
  return (
    <section className={classNames('rich-article-row', 'text-section')}>
      <div className="rich-article-col">{markdownProcessor.processSync(content).result}</div>
    </section>
  )
}

RichArticleTextSection.propTypes = {
  content: PropTypes.string,
}

const RichArticleDividerImage = ({ asset, alt }) => {
  return (
    <div className={classNames('rich-article-row', 'divider-image')}>
      <div className="rich-article-image-container">
        <GatsbyImage
          className="rich-article-image"
          image={getGatsbyImageData(
            asset,
            {
              width: 1200,
            },
            clientConfig.sanity
          )}
          alt={alt}
        />
      </div>
    </div>
  )
}

RichArticleDividerImage.propTypes = {
  asset: PropTypes.object,
  alt: PropTypes.string,
}

const CtaWithImage = ({ title, link, image }) => {
  return (
    <div className="img-and-cta">
      <div className="img-wrapper">
        <GatsbyImage
          image={getGatsbyImageData(
            image.asset,
            {
              width: 210,
              height: 150,
            },
            clientConfig.sanity
          )}
        />
      </div>
      <h2 className="cta-wrapper">
        <Link to={link.target[0].href} target="_blank" rel="noreferrer">
          {title}
        </Link>
      </h2>
    </div>
  )
}

CtaWithImage.propTypes = {
  title: PropTypes.string,
  link: PropTypes.object,
  image: PropTypes.object,
}

const RichArticleComponentList = {
  richArticleDividerImage: RichArticleDividerImage,
  richArticleTextSection: RichArticleTextSection,
  ctaWithImage: CtaWithImage,
}

const RichArticleComponents = (type) => {
  if (typeof RichArticleComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return RichArticleComponentList[type]
}

const RichArticle = ({ className, content }) => {
  return (
    <div className={className}>
      <div className="rich-article">
        <div className="rich-article-container">
          {content &&
            content.map((item) =>
              React.createElement(RichArticleComponents(item._type), {
                key: item._key,
                ...item,
              })
            )}
        </div>
      </div>
    </div>
  )
}

RichArticle.defaultProps = {
  className: '',
}

RichArticle.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object),
}

const StyledRichArticle = styled(RichArticle)(({ theme }) => ({
  '.rich-article': {
    position: 'relative',
    color: 'white',
    '&:before': {
      content: '""',
      display: 'block',
      backgroundColor: 'white',
      paddingTop: '2px',
    },
  },
  '.rich-article-container': {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '36px 32px',
    boxSizing: 'border-box',
  },
  '.rich-article-row': {
    display: 'flex',
    flexWrap: 'wrap',
  },
  '.text-section + .text-section': {
    marginTop: '25px',
    borderTop: '3px solid #fff',
    paddingTop: '25px',
  },
  '.divider-image': {
    marginTop: '25px',
    marginBottom: '25px',
  },
  '.rich-article-col': {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    padding: '0',
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  '.rich-article-image-container': {
    position: 'relative',
    width: '100%',
  },
  '.rich-article-image': {
    height: '400px',
  },
  h2: {
    fontFamily: theme.fontFamilies.gotham,
    fontSize: '20px',
    marginBottom: '8px',
  },
  h3: {
    fontWeight: '500',
    fontSize: '15px',
    marginBottom: '8px',
  },
  ul: {
    listStyleType: 'disc',
  },
  p: {
    '&~ul, &~ol': {
      marginTop: '-8px',
    },
    '&~h2': {
      marginTop: '32px',
    },
  },
  'p, ul, ol': {
    fontFamily: theme.fontFamilies.gotham,
    fontWeight: 'normal',
    fontSize: '11px',
    letterSpacing: '0.66px',
    lineHeight: '2',
    marginBottom: '16px',
  },
  'ul, ol': {
    paddingLeft: '16.5px',
  },
  a: {
    color: 'inherit',
    borderBottom: '1px white dotted',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: '#e64b37',
      borderBottom: '1px #e64b37 solid',
    },
    '&:active': {
      outline: '1px solid white !important',
      outlineOffset: '5px !important',
    },
  },
  '.img-and-cta': {
    display: 'flex',
    paddingTop: '32px',
  },
  '.cta-wrapper': {
    fontSize: '30px',
    letterSpacing: '-1.5px',
    marginTop: 'auto',
    marginBottom: 'auto',
    a: {
      borderBottomColor: 'transparent',
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
        borderBottom: '1px #fff solid',
      },
    },
  },
  '@media(min-width: 576px)': {
    '.rich-article-container': {
      padding: '72px 48px',
    },
  },
  '@media(min-width: 768px)': {
    '.rich-article': {
      '&:before': {
        paddingTop: '4px',
      },
    },
    '.rich-article-container': {
      padding: '64px 64px',
    },
    '.img-and-cta': {
      justifyContent: 'center',
      paddingTop: '48px',
    },
  },
  '@media(min-width: 992px)': {
    '.rich-article': {
      '&:before': {
        paddingTop: '8px',
      },
    },
    '.rich-article-container': {
      padding: '88px 64px',
    },
    '.rich-article-col': {
      columnCount: 2,
      columnGap: '88px',
    },
    h2: {
      fontSize: '24px',
    },
    h3: {
      fontSize: '16px',
    },
    'p, ul, ol': {
      fontSize: '12px',
    },
  },
}))

export default StyledRichArticle
