import type {
  BlockContentProps,
  ListRendererProps,
  TypeSerializerProps,
} from '@sanity/block-content-to-react'
import React, { FC } from 'react'

import { PrivacyNoticeLink } from '../../terms/terms-links/PrivacyNoticeLink'
import { TermsOfSaleLink } from '../../terms/terms-links/TermsOfSaleLink'
import { TermsOfUseLink } from '../../terms/terms-links/TermsOfUseLink'
import type { SetCurrentlyOpenTermsModal } from '../../terms/types'
import { TermsMarkComponentType } from '../../terms/types'
import * as Styled from './styled'

interface MarkRendererProps<TMark extends Record<string, unknown>> {
  mark: TMark
}

const BlockRenderer: FC<TypeSerializerProps> = ({ children, node }) => {
  const { style } = node
  switch (style) {
    case 'normal':
      return <Styled.Paragraph>{children}</Styled.Paragraph>
    case 'h1':
      return <Styled.Heading1>{children}</Styled.Heading1>
    case 'h2':
      return <Styled.Heading2>{children}</Styled.Heading2>
    case 'h3':
      return <Styled.Heading3>{children}</Styled.Heading3>
    case 'h4':
      return <Styled.Heading4>{children}</Styled.Heading4>
    case 'h5':
      return <Styled.Heading5>{children}</Styled.Heading5>
    case 'h6':
      return <Styled.Heading6>{children}</Styled.Heading6>
    case 'blockquote':
      return <Styled.Blockquote>{children}</Styled.Blockquote>
    default:
      return <div>{children}</div>
  }
}

const ListItemRenderer: FC = ({ children }) => {
  return <li>{children}</li>
}

const ListRenderer: FC<ListRendererProps> = ({ children }) => {
  return <Styled.List>{children}</Styled.List>
}

const LinkMarkRenderer: FC<MarkRendererProps<{ href?: string; anchorHref?: string }>> = ({
  mark,
  children,
}) => {
  const { anchorHref, href } = mark
  return href ? (
    <Styled.Anchor href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </Styled.Anchor>
  ) : (
    <Styled.Anchor href={anchorHref}>{children}</Styled.Anchor>
  )
}

const AnchorIdMarkRenderer: FC<MarkRendererProps<{ id?: string }>> = ({ mark, children }) => {
  const { id } = mark
  return <span id={id}>{children}</span>
}

const getComponentBlockMarkRenderer = ({
  setCurrentlyOpenTermsModal,
}: {
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
}) => {
  const ComponentBlockMarkRenderer: FC<MarkRendererProps<{
    component?: TermsMarkComponentType
  }>> = ({ mark, children }) => {
    const { component } = mark
    switch (component) {
      case 'privacyNoticeLink':
        return <PrivacyNoticeLink setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal} />
      case 'termsOfSaleLink':
        return <TermsOfSaleLink setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal} />
      case 'termsOfUseLink':
        return <TermsOfUseLink setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal} />
      default:
        return <div>{children}</div>
    }
  }
  return ComponentBlockMarkRenderer
}

export const getSerializers = ({
  setCurrentlyOpenTermsModal,
}: {
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
}): NonNullable<BlockContentProps['serializers']> => ({
  types: {
    block: BlockRenderer,
  },
  marks: {
    link: LinkMarkRenderer,
    anchorId: AnchorIdMarkRenderer,
    componentBlock: getComponentBlockMarkRenderer({ setCurrentlyOpenTermsModal }),
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
})
