import React, { FC, useContext, useMemo, useState } from 'react'
import { parseISO, format } from 'date-fns'
import { useSanityRoleBasedContent } from '../../../lib/sanity'
import { SanityPageContext } from '../../../lib/sanity/context'
import { SanityTermsSection } from '../../../graphql/gatsby'
import { TermsModalType } from '../../terms/types'
import { TermsModal } from '../../terms/TermsModal'
import { getSerializers } from './serializers'
import * as Styled from './styled'

export type TermsSectionProps = SanityTermsSection

export const TermsSection: FC<TermsSectionProps> = ({ terms, roleBasedContent }) => {
  const { allSanityTerms } = useContext(SanityPageContext)
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  const [currentlyOpenTermsModal, setCurrentlyOpenTermsModal] = useState<TermsModalType | null>(
    null
  )
  const serializers = useMemo(() => getSerializers({ setCurrentlyOpenTermsModal }), [])
  const allTerms = allSanityTerms?.nodes

  if (!terms || !shouldDisplay) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.HeadingContainer>
        <Styled.HeadingTitle>{terms.title}</Styled.HeadingTitle>
        {terms.lastModified && (
          <Styled.LastModified>
            <span>Last Modified:</span>&nbsp;
            <span>{format(parseISO(terms.lastModified), 'PP')}</span>
          </Styled.LastModified>
        )}
      </Styled.HeadingContainer>
      {terms.content && Array.isArray(terms.content) && (
        <Styled.Content blocks={terms.content} serializers={serializers} />
      )}
      {allTerms && (
        <TermsModal
          allTerms={allTerms}
          currentlyOpenTermsModal={currentlyOpenTermsModal}
          open={!!currentlyOpenTermsModal}
          setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
        />
      )}
    </Styled.Container>
  )
}
