import { Link } from '@material-ui/core'
import { MarkRendererProps } from '@sanity/block-content-to-react'
import React, { FC } from 'react'
import { PrivacyNoticeLink } from '../terms-links/PrivacyNoticeLink'
import { TermsOfSaleLink } from '../terms-links/TermsOfSaleLink'
import { TermsOfUseLink } from '../terms-links/TermsOfUseLink'
import { SetCurrentlyOpenTermsModal, TermsMarkComponentType } from '../types'

export type LinkMarkRendererProps = MarkRendererProps<{ href?: string; anchorHref?: string }>
export type AnchorIdMarkRendererProps = MarkRendererProps<{ id?: string }>
export type ComponentBlockMarkRendererProps = MarkRendererProps<{
  component?: TermsMarkComponentType
}>

export const LinkMarkRenderer: FC<LinkMarkRendererProps> = ({ mark, children }) => {
  const { anchorHref, href } = mark
  return href ? (
    <Link href={href} rel="noopener" target="_blank" variant="body2">
      {children}
    </Link>
  ) : (
    <a href={anchorHref}>{children}</a>
  )
}

export const AnchorIdMarkRenderer: FC<AnchorIdMarkRendererProps> = ({ mark, children }) => {
  const { id } = mark
  return <span id={id}>{children}</span>
}

export const getComponentBlockMarkRenderer = ({
  setCurrentlyOpenTermsModal,
}: {
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
}): FC<ComponentBlockMarkRendererProps> => {
  const ComponentBlockMarkRenderer: FC<ComponentBlockMarkRendererProps> = ({ mark, children }) => {
    const { component } = mark
    switch (component) {
      case 'privacyNoticeLink':
        return <PrivacyNoticeLink setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal} />
      case 'termsOfSaleLink':
        return <TermsOfSaleLink setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal} />
      case 'termsOfUseLink':
        return <TermsOfUseLink setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal} />
      default:
        return <div>{children}</div>
    }
  }
  return ComponentBlockMarkRenderer
}
