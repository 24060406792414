import SliderImport from 'react-slick'
import styled from 'styled-components'

import ProductBannerCardImport from '../../products/banners/cards/ProductBannerCard'

export const Slider = styled(SliderImport)`
  .slick-track {
    display: flex;
    gap: 20px;
  }

  .slick-prev {
    left: -60px;
  }

  .slick-next {
    right: -60px;
  }

  .slick-prev,
  .slick-next {
    color: ${({ theme }) => theme.colors.grayscale.black};
    background-color: ${({ theme }) => theme.colors.grayscale.white};
    padding: 25px;
    border-radius: 50%;
    box-shadow: 1px 1px 3px ${({ theme }) => theme.colors.coolGray.cool600};

    &:hover {
      background-color: ${({ theme }) => theme.colors.coolGray.cool150};
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: unset;
    font-size: unset;
    line-height: unset;
    opacity: unset;
    color: unset;
    -webkit-font-smoothing: unset;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;

    li {
      width: 14px;
      height: 14px;
      transition: 0.3s;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.coolGray.cool300};
      color: ${({ theme }) => theme.colors.coolGray.cool300};
    }

    .slick-active {
      width: 18px;
      height: 18px;
      background: ${({ theme }) => theme.colors.coolGray.cool450};
      color: ${({ theme }) => theme.colors.coolGray.cool450};
    }

    button:before {
      content: '' !important;
    }
  }
`

export const ArrowContainer = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`

export const ProductBannerCard = styled(ProductBannerCardImport)`
  @media (min-width: 875px) {
    width: 100%;
    min-width: unset;
  }
`
